import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import Container from "@material-ui/core/Container";


const useStyles = makeStyles(theme => ({
	mainContainer: {
		flex: 1,
		width: '100%',
		height: '100%',
		maxWidth: '100%',
		padding: theme.spacing(0),
		overflowY: "hidden",
		'& > *': {
			width: '100%',
			height: '100%',
			overflowY: "hidden",			
		},
	},
}));

const PBI = () => {
	const classes = useStyles();
	const { powerReportId } = useParams();
	const [powerReportUrl, setPowerReportUrl] = useState('');
	const [powerReportData, setPowerReportData] = useState('');
	const [accessToken, setAccessToken] = useState('');

	useEffect(() => {
		const findPowerReport = async () => {
			try {
				setAccessToken('');
				setPowerReportUrl('');
				setPowerReportData('');
				const powerReport = await api.get(`/power-reports/${powerReportId}`);
				setPowerReportData(powerReport.data);
				const { data } = await api.post(`/power-reports/getAccessToken/${powerReportId}`);
				setAccessToken(data.accessToken);
				setPowerReportUrl(data.embedUrl[0]);
			} catch (error) {
				console.error(error);
				toast.error("Falha ao carregar, verifique as configurações:", error);
			}
		};
		findPowerReport();
	}, [powerReportId]);


	return (
		
	<Container className={classes.mainContainer}>
		{powerReportUrl && (
			<PowerBIEmbed 
				key={accessToken}
				embedConfig={{
					type: 'report',
					id: powerReportUrl.reportID,
					embedUrl: powerReportUrl.embedUrl,
					accessToken: accessToken,
					tokenType: models.TokenType.Embed,
					settings: {
						panes: {
							filters: {
								expanded: false,
								visible: false
							}
						},
						background: models.BackgroundType.Transparent,
					},
					localeSettings: {
						language: powerReportData.language,
						formatLocale: powerReportData.localFormat
					},
				}}
				eventHandlers={
					new Map([
						['loaded', function () { console.log('Report loaded'); }],
						['rendered', function () { console.log('Report rendered'); }],
						['error', function (event) { console.log(event.detail); }],
						['visualClicked', () => console.log('Visual clicked')]
					])
				}
			/>
		)}
	</Container>
		
	);
};

export default PBI;
