import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

function Copyright () {
    const [env, setEnv] = useState(false);

    useEffect(() => {
        switch(process.env.NODE_ENV) {
            case "development": setEnv("DEV"); break;
            case "staging": setEnv("STG"); break;
            case "production": setEnv(""); break;
            default: setEnv("");
        }
    }, [])
    
    return (
		<Typography variant="body2" color="primary" align="center">
			Copyright CODEFI v 1.0.0 - {new Date().getMonth() + 1}/{new Date().getFullYear()}
 			<p>{env}</p>
 		</Typography>
 	);
}

export default Copyright;