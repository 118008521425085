import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useContext,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import FileModal from "../../components/FileModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import RoleModal from "../../components/RoleModal";

const reducer = (state, action) => {
    if (action.type === "LOAD_ROLES") {
        const roles = action.payload;
        const newRole = [];

        roles.forEach((role) => {
            const roleIndex = state.findIndex((s) => s.id === role.id);
            if (roleIndex !== -1) {
                state[roleIndex] = role;
            } else {
                newRole.push(role);
            }
        });

        return [...state, ...newRole];
    }

    if (action.type === "UPDATE_ROLE") {
        const role = action.payload;
        const roleIndex = state.findIndex((s) => s.id === role.id);

        if (roleIndex !== -1) {
            state[roleIndex] = role;
            return [...state];
        } else {
            return [role, ...state];
        }
    }

    if (action.type === "DELETE_TAG") {
        const roleId = action.payload;

        const roleIndex = state.findIndex((s) => s.id === roleId);
        if (roleIndex !== -1) {
            state.splice(roleIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Roles = () => {
    const classes = useStyles();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [deletingRole, setDeletingRole] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [roles, dispatch] = useReducer(reducer, []);
    const [roleModalOpen, setRoleModalOpen] = useState(false);

    const fetchRoles = useCallback(async () => {
        try {
            const { data } = await api.get("/roles/", {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_ROLES", payload: data.roles });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }, [searchParam, pageNumber]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchRoles();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, fetchRoles]);

    useEffect(() => {
        const socket = socketConnection({ companyId: user.companyId });

        socket.on(`company-${user.companyId}-role`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_ROLE", payload: data.roles });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_ROLE", payload: +data.roleId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [user]);

    const handleOpenRoleModal = () => {
        setSelectedRole(null);
        setRoleModalOpen(true);
    };

    const handleCloseRoleModal = () => {
        setSelectedRole(null);
        setRoleModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditRole = (role) => {
        setSelectedRole(role);
        setRoleModalOpen(true);
    };

    const handleDeleteRole = async (roleId) => {
        try {
            await api.delete(`/roles/${roleId}`);
            toast.success(i18n.t("role.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingRole(null);
        setSearchParam("");
        setPageNumber(1);

        dispatch({ type: "RESET" });
        setPageNumber(1);
        await fetchRoles();
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={deletingRole && `${i18n.t("role.confirmationModal.deleteTitle")}`}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteRole(deletingRole.id)}
            >
                {i18n.t("role.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <RoleModal
                open={roleModalOpen}
                onClose={handleCloseRoleModal}
                reload={fetchRoles}
                aria-labelledby="form-dialog-title"
                roleId={selectedRole && selectedRole.id}
            />
            <MainHeader>
                <Title>{i18n.t("role.title")} ({roles.length})</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("contacts.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenRoleModal}
                    >
                        {i18n.t("role.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t("role.table.name")}</TableCell>
                            <TableCell align="center">
                                {i18n.t("role.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {roles.map((role) => (
                                <TableRow key={role.id}>
                                    <TableCell align="center">
                                        {role.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton size="small" onClick={() => handleEditRole(role)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingRole(role);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Roles;
