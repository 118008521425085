import { useEffect, useState } from "react"

export const MustHavePermissions = ({ permissions, content }) => {
	const [allPermissions, setAllPermissions] = useState([]);

	useEffect(() => {
		setAllPermissions(JSON.parse(localStorage.getItem("permissions")));
	}, [])

	if (!allPermissions)
		return false;

	return (
		!permissions || permissions.some(permission => allPermissions?.includes(permission)) ? content : null
	);
}

export const CanRun = (permissions) => {
	const allPermissions = JSON.parse(localStorage.getItem("permissions"));

	return allPermissions && permissions.some(permission => allPermissions.includes(permission));
}