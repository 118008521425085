import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const FileListSelect = ({ selectedFileListIds, onChange, multiple = true, title = i18n.t("fileListSelect.inputLabel") }) => {
	const classes = useStyles();
	const [fileLists, setFileLists] = useState([]);

	useEffect(() => {

		fetchQueues();

	}, []);

	const fetchQueues = async () => {
		try {
			const { data } = await api.get("/files/list");
			setFileLists(data);
		} catch (err) {
			toastError(err);
		}
	}

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div >
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel shrink={selectedFileListIds ? true : false} >{title}</InputLabel>
				<Select
					label={title}
					multiple={multiple}
					labelWidth={60}
					value={selectedFileListIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={selected => {
						return (
							<div className={classes.chips}>
								{selected?.length > 0 && multiple ? (
									fileLists.filter(q => selected.includes(q.id)).map(fileList => {
										return <Chip
												key={fileList.id}
												variant="outlined"
												label={fileList.name}
												className={classes.chip}
											/>;
									})

								) :
									(
										<Chip
											key={selected}
											variant="outlined"
											style={{ backgroundColor: fileLists.find(q => q.id === selected)?.color }}
											label={fileLists.find(q => q.id === selected)?.name}
											className={classes.chip}
										/>
									)
								}

							</div>
						)
					}}
				>
					{!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
					{fileLists.map(fileList => (
						<MenuItem key={fileList.id} value={fileList.id}>
							{fileList.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default FileListSelect;
