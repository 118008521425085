import axios from "axios";

const baseBackendUrl = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
	baseURL: baseBackendUrl,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: baseBackendUrl
});

export default api;
