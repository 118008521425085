import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import WhatsApps from "../pages/WhatsApps/";
import PBI from "../pages/PBI/";
import SettingsCustom from "../pages/SettingsCustom/";
import Financeiro from "../pages/Financeiro/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import MessagesAPI from "../pages/MessagesAPI/";
import Helps from "../pages/Helps/";
import ContactLists from "../pages/ContactLists/";
import ContactListItems from "../pages/ContactListItems/";
// import Companies from "../pages/Companies/";
import QuickMessages from "../pages/QuickMessages/";
import Kanban from "../pages/Kanban";
import { AuthProvider } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Schedules from "../pages/Schedules";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import CampaignReport from "../pages/CampaignReport";
import Annoucements from "../pages/Annoucements";
import Chat from "../pages/Chat";
import ToDoList from "../pages/ToDoList/";
import Subscription from "../pages/Subscription/";
import Files from "../pages/Files/";
import Roles from "../pages/Roles/";
import Prompts from "../pages/Prompts";
import QueueIntegration from "../pages/QueueIntegration";
import ForgetPassword from "../pages/ForgetPassWord/"; // Reset PassWd
import { MustHavePermissions } from "../components/MustHavePermission";
import PowerReports from "../pages/PowerReports";
import PowerBIConfigs from "../pages/PowerBIConfigs";

const Routes = ({company, setCompany}) => {
  const [showCampaigns, setShowCampaigns] = useState(false);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <TicketsContextProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
			      <Route exact path="/forgetpsw" component={ForgetPassword} /> 
            {/* <Route exact path="/create-company" component={Companies} /> */}
            <WhatsAppsProvider>
              <LoggedInLayout setCompany={setCompany}>
                <MustHavePermissions permissions={["Dashboard.Search"]}
                  content={
                    <Route exact path="/" component={Dashboard} isPrivate />
                  }
                />
                <MustHavePermissions permissions={["Tickets.Search"]}
                  content={
                    <Route
                      exact
                      path="/tickets/:ticketId?"
                      component={TicketResponsiveContainer}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["WhatsApp.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/whatsapps"
                      component={WhatsApps}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["QuickMessages.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/quick-messages"
                      component={QuickMessages}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["Tasks.Search"]}
                  content={
                    <Route
                      exact
                      path="/todolist"
                      component={ToDoList}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["Schedules.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/schedules"
                      component={Schedules}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["Tags.Search"]}
                  content={ 
                    <Route exact path="/tags" component={Tags} isPrivate />
                }/>
                <MustHavePermissions permissions={["Contacts.Search"]}
                  content={ 
                    <Route exact path="/contacts" component={Contacts} isPrivate />
                }/>
                <MustHavePermissions permissions={["Help.Search"]}
                  content={ 
                    <Route exact path="/helps" component={Helps} isPrivate />
                }/>
                <MustHavePermissions permissions={["Users.Search"]}
                  content={ 
                    <Route exact path="/users" component={Users} isPrivate />
                }/>
                <MustHavePermissions permissions={["Files.Search"]}
                  content={ 
                    <Route exact path="/files" component={Files} isPrivate />
                }/>
                <MustHavePermissions permissions={["Roles.Search"]}
                  content={ 
                    <Route exact path="/roles" component={Roles} isPrivate />
                }/>
                <MustHavePermissions permissions={["Prompts.Search"]}
                  content={ 
                    <Route exact path="/prompts" component={Prompts} isPrivate />
                }/>
                <MustHavePermissions permissions={["QueueIntegrations.Search"]}
                  content={ 
                    <Route exact path="/queue-integration" component={QueueIntegration} isPrivate />
                }/>
                <MustHavePermissions permissions={["MessagesAPI.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/messages-api"
                      component={MessagesAPI}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["Settings.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/settings"
                      component={SettingsCustom}
                      isPrivate
                    />
                }/>
				        <MustHavePermissions permissions={["Kanban.Search"]}
                  content={ 
                    <Route 
                      exact
                      path="/kanban"
                      component={Kanban}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["Financial.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/financeiro"
                      component={Financeiro}
                      isPrivate
                    />
                }/>
                <MustHavePermissions permissions={["Queues.Search"]}
                  content={ 
                    <Route exact path="/queues" component={Queues} isPrivate />
                }/>
                <MustHavePermissions permissions={["Announcements.Search"]}
                  content={ 
                    <Route
                      exact
                      path="/announcements"
                      component={Annoucements}
                      isPrivate
                    />
                }/>
                <Route
                  exact
                  path="/subscription"
                  component={Subscription}
                  isPrivate
                />
                <MustHavePermissions permissions={["Chats.Search"]}
                  content={ 
                    <Route exact path="/chats/:id?" component={Chat} isPrivate />
                }/>
                <MustHavePermissions permissions={["Campaigns.Search"]}
                  content={ 
                    showCampaigns && (
                      <>
                        <Route
                          exact
                          path="/contact-lists"
                          component={ContactLists}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/contact-lists/:contactListId/contacts"
                          component={ContactListItems}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/campaigns"
                          component={Campaigns}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/campaign/:campaignId/report"
                          component={CampaignReport}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/campaigns-config"
                          component={CampaignsConfig}
                          isPrivate
                        />
                      </>
                    )
                }/>
                <MustHavePermissions permissions={["PowerReports.Search"]}
                  content={ 
                    showCampaigns && (
                      <>
                        <Route
                          exact
                          path="/power-reports"
                          component={PowerReports}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/power-reports/settings"
                          component={PowerBIConfigs}
                          isPrivate
                        />
                        <Route
                          exact
                          path="/power-report/:powerReportId"
                          component={PBI}
                          isPrivate
                        />
                      </>
                    )
                }/>
              </LoggedInLayout>
            </WhatsAppsProvider>
          </Switch>
          <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
