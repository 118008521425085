import React, { useState } from "react";
import { TextField, Button, Chip, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { i18n } from "../../translate/i18n";
import moment from "moment";

function TimeSelect({ timesList, setTimesList }) {
  const [time, setTime] = useState("");

  const handleAddTime = () => {
    if (time.trim() !== "") {
      const set = new Set(timesList);
      const datetime = moment().hours(time.split(":")[0]).minutes(time.split(":")[1]);
      set.add(datetime);
      setTimesList([...set]);
      setTime(undefined);
    }
  };

  const handleDeleteTime = (index) => {
    const newTimesList = [...timesList];
    newTimesList.splice(index, 1);
    setTimesList(newTimesList);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <TextField
          label={i18n.t("scheduleModal.form.times")}
          type="time"
          variant="outlined"
          margin="dense"
          fullWidth
          value={time}
          InputLabelProps={{ shrink: true }} 
          onChange={(e) => setTime(e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" color="primary" onClick={handleAddTime}>
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        {timesList.map((time, index) => (
          <Chip
            key={index}
            label={`${time.format("HH:mm")}`}
            onDelete={() => handleDeleteTime(index)}
            style={{ marginRight: "8px", marginBottom: "8px" }}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default TimeSelect;
