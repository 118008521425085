import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
  Paper,
  Chip,
  IconButton,
  Checkbox,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize, head } from "lodash";
import QueueSelect from "../QueueSelect";
import DaySelect from "../DaySelect";
import TimeSelect from "../TimeSelect";
import MessageVariablesPicker from "../MessageVariablesPicker";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AttachFile from "@material-ui/icons/AttachFile";
import ConfirmationModal from "../ConfirmationModal";
import InputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      paddingRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    minWidth: 120,
  },
  chip: {
    margin: 2,
    width: "100%",
  },
  dateTimeConfig: {
    "& > *": {
      marginTop: theme.spacing(1),
    },
  },
  scheduleModalTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ScheduleSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t("forms.required")),
  phone: Yup.string()
    .notRequired()
    .nullable()
    .when(["sendToContact", "forMe"], {
      is: (sendToContact, forMe) => !sendToContact && !forMe,
      then: Yup.string().required(i18n.t("forms.required")).min(12, i18n.t("forms.minNumber"))
    }),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, reload }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    name: "",
    body: "",
    forMe: true,
    singleMessage: true,
    sendToContact: false,
    phone: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    queueOptionId: "",
    sentAt: "",
    startAt: "",
    stopt: "",
    repeatEveryDays: 0,
  };

  const initialWeekDays = {
    seg: false,
    ter: false,
    qua: false,
    qui: false,
    sex: false,
    sab: false,
    dom: false,
  };

  const initialContact = {
    id: "",
    name: "",
  };

  const [schedule, setSchedule] = useState(initialState);
  const [queueOptions, setQueueOptions] = useState([]);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [tab, setTab] = useState(0);
  const [selectedMonthDays, setSelectedMouthDays] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [weekDays, setWeekDays] = useState(initialWeekDays);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);
  const messageInputRef = useRef();
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      try {
        (async () => {
          const queueOptions = await api.get(`/queue-options`, {
            params: { companyId },
          });
          setQueueOptions(queueOptions.data);

          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId: companyId },
          });
          let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList]);
          }
          if (contactId) {
            setSchedule((prevState) => {
              return { ...prevState, contactId };
            });
          }

          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);
          setSchedule((prevState) => {
            return {
              ...prevState,
              ...data,
              sendAt: data.sendAt
                ? moment(data.sendAt).format("YYYY-MM-DDTHH:mm")
                : "",
              startAt: data.startAt
                ? moment(data.startAt).format("YYYY-MM-DDTHH:mm")
                : "",
              stopAt: data.stopAt
                ? moment(data.stopAt).format("YYYY-MM-DDTHH:mm")
                : "",
              phone: data.phone ?? ""
            };
          });
          setSelectedQueueIds(data.queues.map((queue) => queue.id));
          const dataWeekDays = data.days.filter(
            (record) => record.dayBy == "week"
          );
          Object.keys(weekDays).forEach(
            (key) =>
              (weekDays[key] = dataWeekDays
                .map((record) => record.day)
                .includes(key))
          );
          setSelectedTimes(data.times.map((record) => moment(record.time)));
          setSelectedMouthDays(
            data.days
              .filter((record) => record.dayBy == "month")
              .map((record) => record.day)
          );
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [scheduleId, open, user]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      try {
        (async () => {
          const queueOptions = await api.get(`/queue-options`, {
            params: { companyId, queueIds: selectedQueueIds },
          });
          setQueueOptions(queueOptions.data);
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [selectedQueueIds]);

  const handleClickMsgVar = async (msgVar, setValueFunc) => {
    const el = messageInputRef.current;
    const firstHalfText = el.value.substring(0, el.selectionStart);
    const secondHalfText = el.value.substring(el.selectionEnd);
    const newCursorPos = el.selectionStart + msgVar.length;

    setValueFunc("body", `${firstHalfText}${msgVar}${secondHalfText}`);

    await new Promise((r) => setTimeout(r, 100));
    messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`);
      setSchedule((prev) => ({
        ...prev,
        mediaPath: null,
      }));
      toast.success(i18n.t("scheduleModal.toasts.deleted"));
      if (typeof reload == "function") {
        reload();
      }
    }
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleClose = () => {
    onClose();
    setSchedule(initialState);
    setSelectedQueueIds([]);
    setSelectedMouthDays([]);
    setWeekDays(initialWeekDays);
    setSelectedTimes([]);
  };

  const handleSaveSchedule = async (values) => {
    let selectedWeekDays = [];
    Object.keys(weekDays).forEach((key) => {
      if (weekDays[key]) selectedWeekDays.push(key);
    });

    const scheduleData = {
      ...values,
      phone: !values.sendToContact ? values.phone.replace(/\D/g, "") : null,
      startAt: moment(values.startAt),
      stopAt: values.stopAt ? moment(values.stopAt) : "",
      userId: user.id,
      queues: selectedQueueIds,
      times: selectedTimes,
      weekDays: selectedWeekDays,
      monthDays: selectedMonthDays,
      queueOptionId: values.queueOptionId ? values.queueOptionId : null,
    };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${scheduleId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload("UPDATE_SCHEDULES");
      }
      setSchedule(initialState);
      handleClose();
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const DatetimeConfig = () => {
    return (
      <>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setTab(v)}
          style={{ marginBottom: "16px" }}
        >
          <Tab label="Rotina" />
          <Tab label="Dias específicos do mês" />
        </Tabs>
        {tab === 0 && (
          <div className={classes.dateTimeConfig}>
            <Field
              as={TextField}
              type="number"
              label={i18n.t("scheduleModal.form.repeatEveryDays")}
              name="repeatEveryDays"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <div style={{ display: "flex" }}>
              {Object.keys(weekDays).map((day) => (
                <Chip
                  key={day}
                  style={{ backgroundColor: weekDays[day] ? "grey" : "white" }}
                  onClick={() =>
                    setWeekDays({ ...weekDays, [day]: !weekDays[day] })
                  }
                  variant="outlined"
                  label={day.toLocaleUpperCase()}
                  className={classes.chip}
                />
              ))}
            </div>
            <TimeSelect
              setTimesList={setSelectedTimes}
              timesList={selectedTimes}
            />
          </div>
        )}
        {tab === 1 && (
          <div className={classes.dateTimeConfig}>
            <DaySelect
              setDaysList={setSelectedMouthDays}
              daysList={selectedMonthDays}
            />
            <TimeSelect
              setTimesList={setSelectedTimes}
              timesList={selectedTimes}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <div className={classes.scheduleModalTitle}>
            <span>
              {schedule.status === "ERRO"
                ? "Erro de Envio"
                : `Mensagem ${capitalize(schedule.status)}`}
            </span>
            <div style={{ display: "none" }}>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                ref={attachmentFile}
                onChange={(e) => handleAttachmentFile(e)}
              />
            </div>
            <span>
              {schedule.lastSentAt
                ? `${i18n.t("scheduleModal.title.lastSent")}: ${moment(
                    new Date(schedule.lastSentAt)
                  ).format("DD/MM/yy HH:mm")}`
                : ""}
            </span>
          </div>
        </DialogTitle>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("scheduleModal.form.name")}
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <Grid spacing={2} container>
                  <Grid className={classes.checkbox} xs={12} sm={3} md={3} item>
                    <InputLabel id="forMe">
                      {i18n.t("scheduleModal.form.forMe")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="forMe"
                      checked={values.forMe}
                      variant="outlined"
                      margin="dense"
                      className={classes.checkbox}
                    />
                  </Grid>
                  <Grid className={classes.checkbox} xs={12} sm={4} md={4} item>
                    <InputLabel id="singleMessage">
                      {i18n.t("scheduleModal.form.singleMessage")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="singleMessage"
                      checked={values.singleMessage}
                      variant="outlined"
                      margin="dense"
                      className={classes.checkbox}
                    />
                  </Grid>
                  <Grid className={classes.checkbox} xs={12} sm={5} md={5} item>
                    <InputLabel id="sendToContact">
                      {i18n.t("scheduleModal.form.sendToContact")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="sendToContact"
                      checked={values.sendToContact}
                      variant="outlined"
                      margin="dense"
                      className={classes.checkbox}
                    />
                  </Grid>
                </Grid>
                {!values.forMe && (
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    className={classes.formControl}
                  >
                    {values.sendToContact && (
                      <>
                        <InputLabel id="contact-selection-label">
                          {i18n.t("scheduleModal.form.contact")}
                        </InputLabel>
                        <Field
                          as={Select}
                          label={i18n.t("scheduleModal.form.contact")}
                          placeholder={i18n.t("scheduleModal.form.contact")}
                          labelId="contact-selection-label"
                          id="contactId"
                          name="contactId"
                          error={touched.contactId && Boolean(errors.contactId)}
                        >
                          {contacts &&
                            contacts.map((contact) => (
                              <MenuItem key={contact.id} value={contact.id}>
                                {contact.name}
                              </MenuItem>
                            ))}
                        </Field>
                      </>
                    )}
                    {!values.sendToContact && (
                      <>
                        <InputMask
                          mask="+55 (99) 99999-9999"
                          onChange={(e) => setFieldValue("phone", e.target.value)}
                          name="phone"
                          value={values.phone}
                        >
                          {() => (
                            <TextField
                              type="text"
                              label={i18n.t("scheduleModal.form.phone")}
                              name="phone"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              helperText={touched.phone && errors.phone}
                              error={touched.phone && Boolean(errors.phone)}
                            />
                          )}
                        </InputMask>
                      </>
                    )}
                  </FormControl>
                )}
                <Field
                  as={TextField}
                  rows={9}
                  multiline={true}
                  label={i18n.t("scheduleModal.form.body")}
                  name="body"
                  inputRef={messageInputRef}
                  error={touched.body && Boolean(errors.body)}
                  helperText={touched.body && errors.body}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <Grid item>
                  <MessageVariablesPicker
                    disabled={isSubmitting}
                    onClick={(value) => handleClickMsgVar(value, setFieldValue)}
                  />
                </Grid>
                {values.body.length == 0 && (
                  <>
                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={(values) => setSelectedQueueIds(values)}
                    />
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="queueOption-selection-label">
                        {i18n.t("scheduleModal.form.queueOption")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("scheduleModal.form.queueOption")}
                        placeholder={i18n.t("scheduleModal.form.queueOption")}
                        labelId="queueOption-selection-label"
                        id="queueOptionId"
                        name="queueOptionId"
                        error={
                          touched.queueOptionId && Boolean(errors.queueOptionId)
                        }
                      >
                        <MenuItem key="" value="">
                          Selecione
                        </MenuItem>
                        {queueOptions &&
                          queueOptions.map((queueOption) => (
                            <MenuItem
                              key={queueOption.id}
                              value={queueOption.id}
                            >
                              {queueOption.title}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </>
                )}
                {values.singleMessage && (
                  <Field
                    as={TextField}
                    label={i18n.t("scheduleModal.form.sendAt")}
                    type="datetime-local"
                    name="sendAt"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAt && Boolean(errors.sendAt)}
                    helperText={touched.sendAt && errors.sendAt}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                )}
                {!values.singleMessage && (
                  <>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("scheduleModal.form.startAt")}
                        type="datetime-local"
                        name="startAt"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={touched.startAt && Boolean(errors.startAt)}
                        helperText={touched.startAt && errors.startAt}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("scheduleModal.form.stopAt")}
                        type="datetime-local"
                        name="stopAt"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                    <DatetimeConfig />
                  </>
                )}
                {(schedule.mediaPath || attachment) && (
                  <Grid xs={12} item>
                    <Button startIcon={<AttachFile />}>
                      {attachment ? attachment.name : schedule.mediaName}
                    </Button>
                    <IconButton
                      onClick={() => setConfirmationOpen(true)}
                      color="secondary"
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                {!attachment && !schedule.mediaPath && (
                  <Button
                    color="primary"
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("quickMessages.buttons.attach")}
                  </Button>
                )}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {schedule.sentAt === null || schedule.sentAt === ""
                    ? i18n.t("scheduleModal.buttons.cancel")
                    : i18n.t("scheduleModal.buttons.back")}
                </Button>
                {(schedule.sentAt === null || schedule.sentAt === "") && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {scheduleId
                      ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                      : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
