import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
  const [locale, setLocale] = useState();
  const [company, setCompany] = useState(null);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const preferredTheme = window.localStorage.getItem("preferredTheme");
  const [mode, setMode] = useState(
    preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light"
  );
  const [theme, setTheme] = useState(
    createTheme(getThemeConfig(company, mode), locale)
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("preferredTheme", mode);
  }, [mode]);

  useEffect(() => {
    if (company) {
      setTheme(createTheme(getThemeConfig(company, mode)));
    }
  }, [mode, company]);

  return (
    <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Routes company={company} setCompany={setCompany} />
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const getThemeConfig = (company, mode) => {
  const color = company?.primaryColor ?? "#0060aa";
  return {
    scrollbarStyles: {
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: color,
      },
    },
    scrollbarStylesSoft: {
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
      },
    },
    palette: {
      type: mode,
      primary: {
        main: mode === "light" ? color : "#FFFFFF",
      },
      textPrimary:
        mode === "light" ? color : "#FFFFFF",
      borderPrimary:
        mode === "light" ? color : "#FFFFFF",
      dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
      light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
      tabHeaderBackground: mode === "light" ? "#EEE" : "#666",
      optionsBackground: mode === "light" ? "#fafafa" : "#333",
      options: mode === "light" ? "#fafafa" : "#666",
      fontecor: mode === "light" ? "#128c7e" : "#fff",
      fancyBackground: mode === "light" ? "#fafafa" : "#333",
      bordabox: mode === "light" ? "#eee" : "#333",
      newmessagebox: mode === "light" ? "#eee" : "#333",
      inputdigita: mode === "light" ? "#fff" : "#666",
      contactdrawer: mode === "light" ? "#fff" : "#666",
      announcements: mode === "light" ? "#ededed" : "#333",
      login: mode === "light" ? "#fff" : "#1C1C1C",
      announcementspopover: mode === "light" ? "#fff" : "#666",
      chatlist: mode === "light" ? "#eee" : "#666",
      boxlist: mode === "light" ? "#ededed" : "#666",
      boxchatlist: mode === "light" ? "#ededed" : "#333",
      total: mode === "light" ? "#fff" : "#222",
      messageIcons: mode === "light" ? "grey" : "#F3F3F3",
      inputBackground: mode === "light" ? "#FFFFFF" : "#333",
      barraSuperior:
        mode === "light"
          ? `linear-gradient(to right, ${color}, ${color} , ${color})`
          : "#666",
      boxticket: mode === "light" ? "#EEE" : "#666",
      campaigntab: mode === "light" ? "#ededed" : "#666",
      mediainput: mode === "light" ? "#ededed" : "#1c1c1c",
    },
    mode,
  };
};

export default App;
