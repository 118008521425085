import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import {
    Formik,
    Form,
    Field
} from "formik";
import { toast } from "react-toastify";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import Table from "@material-ui/core/Table";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        gap: 4
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    groupFields: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12);",
        padding: "8px 0"
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 2000,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const roleSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "nome muito curto")
        .required("Obrigatório"),
    message: Yup.string()
        .required("Obrigatório")
});

const RoleModal = ({ open, onClose, roleId, reload }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const namePermissions = ["Search", "View", "Create", "Update", "Delete"];

    const initialState = {
        name: "",
        description: "",
        permissions: []
    };

    const [role, setRole] = useState(initialState);
    const [permissions, setPermissions] = useState([]);
    const [groupedPermissions, setGroupedPermissions] = useState({});

    useEffect(() => {
        try {
            (async () => {
                const permissions = await api.get(`/permissions/all`);
                const groupedPermissions = {}

                let resource = ''
                let action = ''
                permissions.data.forEach(permission => {
                    resource = permission.value.split(".")[0]
                    if (!groupedPermissions[resource]) {
                        groupedPermissions[resource] = {}
                    }
                    action = permission.value.split(".")[1]

                    if (!groupedPermissions[resource][action])
                        groupedPermissions[resource][action] = permission

                })

                setPermissions(permissions.data);
                setGroupedPermissions(groupedPermissions);

                if (!roleId) return;

                const { data } = await api.get(`/roles/${roleId}`);
                const dataPermissions = data.permissions.map(permission => permission.value)
                setRole({ ...data, permissions: dataPermissions});

            })()
        } catch (err) {
            toastError(err);
        }
    }, [roleId, open]);

    const handleClose = () => {
        setRole(initialState);
        setSelectedQueueIds([]);
        setRole([]);
        onClose();
    };

    const handleSaveRole = async (values) => {

        const permissionIds = permissions.filter(permission => values.permissions?.includes(permission.value)).map(permission => permission.id);

        const roleData = { ...values, permissions: permissionIds };
        try {
            if (roleId) {
                await api.put(`/roles/${roleId}`, roleData)
            } else {
                await api.post("/roles", roleData);
            }

            toast.success(i18n.t("roleModal.success"));
            if (typeof reload == 'function') {
                reload();
            }            
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    const selectPermission = (e, resourse, permission) => {
        let permissionsSet = new Set(role.permissions);
        if (e.target.checked) {
            permissionsSet.add(`${resourse}.${permission}`)
        } else {
            permissionsSet.delete(`${resourse}.${permission}`)
        } 
        setRole({ ...role, permissions: Array.from(permissionsSet)})
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {(roleId ? `${i18n.t("roleModal.title.edit")}` : `${i18n.t("roleModal.title.add")}`)}
                </DialogTitle>
                <Formik
                    initialValues={role}
                    enableReinitialize={true}
                    validationSchema={roleSchema}
                    onSubmit={(values, actions) => {
                        handleSaveRole(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("roleModal.form.name")}
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        value={role.name ?? ''}
                                        onChange={e => setRole({ ...role, name: e.target.value })}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("roleModal.form.description")}
                                        name="description"
                                        error={touched.description && Boolean(errors.description)}
                                        helperText={touched.description && errors.description}
                                        value={role.description ?? ''}
                                        onChange={e => setRole({ ...role, description: e.target.value })}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <Table className={classes.tablePermissions} size="small">
                                    <TableHead>
                                        
                                        <TableCell>Recursos</TableCell>
                                        {
                                            namePermissions.map(name => (
                                                <TableCell key={name}>{i18n.t(`roleModal.form.${name[0].toLowerCase() + name.slice(1)}`)}</TableCell>
                                            ))
                                        }
                                       
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(groupedPermissions).map((resourse, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {
                                                    i18n.exists(`roleModal.form.${resourse[0].toLowerCase() + resourse.slice(1)}`) ? 
                                                        i18n.t(`roleModal.form.${resourse[0].toLowerCase() + resourse.slice(1)}`) :
                                                        resourse
                                                }
                                            </TableCell>
                                            {namePermissions.map((permission, idx) => (
                                            <TableCell key={idx}>
                                                <Field 
                                                    type="checkbox" 
                                                    name={`${resourse}.${permission}`}
                                                    disabled={!permissions.map(permission => permission.value).includes(`${resourse}.${permission}`)}
                                                    checked={role.permissions ? role.permissions.includes(`${resourse}.${permission}`): false}
                                                    onChange={(e) => selectPermission(e, resourse, permission)} 
                                                />
                                            </TableCell>
                                            ))}
                                        </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("roleModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={() => handleSaveRole(role)}
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {roleId
                                        ? `${i18n.t("roleModal.buttons.okEdit")}`
                                        : `${i18n.t("roleModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default RoleModal;