import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { Button, IconButton, StepContent, TextField, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Field, Form } from "formik";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //height: 400,
    [theme.breakpoints.down("sm")]: {
      maxHeight: "20vh",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginRight: "8px",
    flex: 1,
  },
  textAreaField: {
    paddingRight: "8px",
    flex: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

export function QueueOptionStepper({ queueId, options, updateOptions }) {
  const classes = useStyles();
  const [activeOption, setActiveOption] = useState(-1);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    // (async () => {
    //   try {
    //     const { data } = await api.get("/connections");
    //     setConnections(data.connections);
    //   } catch (err) {
    //     toastError(err);
    //   }
    // })();
  }, []);

  const handleOption = (index) => async () => {
    setActiveOption(index);
    const option = options[index];

    if (option !== undefined && option.id !== undefined) {
      try {
        const { data } = await api.request({
          url: "/queue-options",
          method: "GET",
          params: { queueId, parentId: option.id },
        });
        const optionList = data.map((option) => {
          return {
            ...option,
            children: [],
            edition: false,
          };
        });
        option.children = optionList;
        updateOptions();
      } catch (e) {
        toastError(e);
      }
    }
  };

  const handleSave = async (option) => {
    try {
      if (option.id) {
        await api.request({
          url: `/queue-options/${option.id}`,
          method: "PUT",
          data: option,
        });
      } else {
        const { data } = await api.request({
          url: `/queue-options`,
          method: "POST",
          data: option,
        });
        option.id = data.id;
      }
      option.edition = false;
      updateOptions();
    } catch (e) {
      toastError(e);
    }
  };

  const handleEdition = (index) => {
    options[index].edition = !options[index].edition;
    updateOptions();
  };

  const handleDeleteOption = async (index) => {
    const option = options[index];
    if (option !== undefined && option.id !== undefined) {
      try {
        await api.request({
          url: `/queue-options/${option.id}`,
          method: "DELETE",
        });
      } catch (e) {
        toastError(e);
      }
    }
    options.splice(index, 1);
    options.forEach(async (option, order) => {
      option.option = order + 1;
      await handleSave(option);
    });
    updateOptions();
  };

  const handleOptionChangeTitle = (event, index) => {
    options[index].title = event.target.value;
    updateOptions();
  };

  const handleOptionChangeField = (event, index, field) => {
    options[index][field] = event.target.value;
    updateOptions(); 
  };

  const handleChangeConnection = (e) => {
    setSelectedConnection(e.target.value);
  };

  const renderTitle = (index) => {
    const option = options[index];
    if (option.edition) {
      return (
        <>
          <TextField
            value={option.title}
            onChange={(event) => handleOptionChangeTitle(event, index)}
            size="small"
            className={classes.input}
            placeholder="Título da opção"
          />
          {option.edition && (
            <>
              <IconButton
                color="primary"
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={() => handleSave(option)}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.button}
                onClick={() => handleDeleteOption(index)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </>
          )}
        </>
      );
    }
    return (
      <>
        <Typography>
          {option.title !== "" ? option.title : "Título não definido"}
          <IconButton
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => handleEdition(index)}
          >
            <EditIcon />
          </IconButton>
        </Typography>
      </>
    );
  };

  const renderOptionFilters = (index) => {
    const option = options[index];
    if (option.edition) {
      return (
        <Form>
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.keywords")}
            autoFocus
            value={option.keywords}
            onChange={(event) => handleOptionChangeField(event, index, 'keywords')}
            fullWidth
            variant="outlined"
            margin="dense"
            className={classes.textAreaField}
          />
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.message")}
            type="type"
            value={option.message}
            onChange={(event) => handleOptionChangeField(event, index, 'message')}
            multiline
            rows={20}
            fullWidth
            variant="outlined"
            margin="dense"
            className={classes.textAreaField}
          />
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.audioMessage")}
            type="type"
            value={option.audioMessage}
            onChange={(event) => handleOptionChangeField(event, index, 'audioMessage')}
            multiline
            rows={20}
            fullWidth
            variant="outlined"
            margin="dense"
            className={classes.textAreaField}
          />
          {/* <FormControl
            margin="dense"
            variant="outlined"
          >
            <InputLabel>
              {i18n.t("whatsappModal.form.prompt")}
            </InputLabel>
            <Select
              id="dialog-select-prompt"
              value={selectedConnection || ""}
              onChange={handleChangeConnection}
              label={i18n.t("queueModal.form.connection")}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {connections.map((connection) => (
                <MenuItem
                  key={connection.id}
                  value={connection.id}
                >
                  {connection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.functionType")}
            type="type"
            value={option.functionType}
            onChange={(event) => handleOptionChangeField(event, index, 'functionType')}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.textField}
          />
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.function")}
            type="type"
            value={option.function}
            onChange={(event) => handleOptionChangeField(event, index, 'function')}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.textField}
          />
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.connection")}
            type="type"
            value={option.connectionText}
            onChange={(event) => handleOptionChangeField(event, index, 'connectionText')}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.textField}
          />
          {/* <div className={classes.row}> */}
            <Field
              as={TextField}
              label={i18n.t("queueModal.form.queryStatement")}
              type="type"
              value={option.queryStatement}
              onChange={(event) => handleOptionChangeField(event, index, 'queryStatement')}
              multiline
              rows={20}
              fullWidth
              variant="outlined"
              margin="dense"
              className={classes.textField}
            />
            {/* <Field
              as={TextField}
              label={i18n.t("queueModal.form.queryResult")}
              type="type"
              value="z"
              multiline
              rows={20}
              fullWidth
              variant="outlined"
              className={classes.textField}
              margin="dense"
              disabled
            />
          </div> */}
        </Form>
      );
    }
    return (
      <>
        <Typography onClick={() => handleEdition(index)}>
          {option.message}
        </Typography>
      </>
    );
  };

  const handleAddOption = (index) => {
    const optionNumber = options[index].children.length + 1;
    options[index].children.push({
      title: "",
      message: "",
      edition: false,
      option: optionNumber,
      queueId,
      parentId: options[index].id,
      children: [],
    });
    updateOptions();
  };

  const renderStep = (option, index) => {
    return (
      <Step key={index}>
        <StepLabel style={{ cursor: "pointer" }} onClick={handleOption(index)}>
          {renderTitle(index)}
        </StepLabel>
        <StepContent>
          {renderOptionFilters(index)}

          {option.id !== undefined && (
            <>
              <Button
                color="primary"
                size="small"
                onClick={() => handleAddOption(index)}
                startIcon={<AddIcon />}
                variant="outlined"
                className={classes.addButton}
              >
                {i18n.t("queueModal.buttons.okAdd")}
              </Button>
            </>
            )}
            {option.edition && (
              <Button
                color="primary"
                size="small"
                onClick={() => handleSave(option)}
                startIcon={<SaveIcon />}
                variant="contained"
                className={classes.addButton}
              >
                {i18n.t("queueModal.buttons.okEdit")}
              </Button>
            )}
          <QueueOptionStepper
            queueId={queueId}
            options={option.children}
            updateOptions={updateOptions}
          />
        </StepContent>
      </Step>
    );
  };

  const renderStepper = () => {
    return (
      <Stepper
        style={{ marginBottom: 0, paddingBottom: 0 }}
        nonLinear
        activeStep={activeOption}
        orientation="vertical"
      >
        {options.map((option, index) => renderStep(option, index))}
      </Stepper>
    );
  };

  return renderStepper();
}

export function QueueOptions({ queueId }) {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (queueId) {
      const fetchOptions = async () => {
        try {
          const { data } = await api.request({
            url: "/queue-options",
            method: "GET",
            params: { queueId, parentId: -1 },
          });
          const optionList = data.map((option) => {
            return {
              ...option,
              children: [],
              edition: false,
            };
          });
          setOptions(optionList);
        } catch (e) {
          toastError(e);
        }
      };
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStepper = () => {
    if (options.length > 0) {
      return (
        <QueueOptionStepper
          queueId={queueId}
          updateOptions={updateOptions}
          options ={options}
        />
      );
    }
  };

  const updateOptions = () => {
    setOptions([...options]);
  };

  const addOption = () => {
    const newOption = {
      title: "",
      message: "",
      edition: false,
      option: options.length + 1,
      queueId,
      parentId: null,
      children: [],
    };
    setOptions([...options, newOption]);
  };

  return (
    <div className={classes.root}>
      <br />
      <Typography>
        Opções
        <Button
          color="primary"
          size="small"
          onClick={addOption}
          startIcon={<AddIcon />}
          style={{ marginLeft: 10 }}
          variant="outlined"
        >
          Adicionar
        </Button>
      </Typography>
      {renderStepper()}
    </div>
  );
}
