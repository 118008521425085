import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
  Checkbox,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#fff",
  },

  tabmsg: {
    backgroundColor: theme.palette.campaigntab,
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  checkbox: {
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const settingSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Obrigatório"),
  clientID: Yup.string().required("Obrigatório"),
  clientSecret: Yup.string().required("Obrigatório"),
  username: Yup.string().required("Obrigatório"),
  password: Yup.string().required("Obrigatório"),
});

const PowerBIConfigModal = ({
  open,
  onClose,
  settingId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { companyId } = user;

  const initialState = {
    name: "",
    active: false,
    clientID: "",
    clientSecret: "",
    username: "",
    password: "",
    companyId,
  };

  const [campaign, setCampaign] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (initialValues) {
        setCampaign((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!settingId) return;

      api.get(`/powerbi-config/${settingId}`).then(({ data }) => {
        setCampaign(data);
      });
    }
  }, [settingId, open, initialValues, companyId]);

  const handleClose = () => {
    onClose();
    setCampaign(initialState);
  };

  const handleSave = async (values) => {
    try {
      if (settingId) {
        await api.put(`/powerbi-config/${settingId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/powerbi-config", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("powerBIConfigs.toasts.success"));
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {settingId
            ? `${i18n.t("powerBIConfigs.dialog.update")}`
            : `${i18n.t("powerBIConfigs.dialog.new")}`}
        </DialogTitle>
        <Formik
          initialValues={campaign}
          enableReinitialize={true}
          validationSchema={settingSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSave(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Grid spacing={2} container>
                  <Grid xs={9} md={10} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerBIConfigs.dialog.form.name")}
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid className={classes.checkbox} xs={1} md={1} item>
                    <InputLabel id="active">
                      {i18n.t("powerBIConfigs.dialog.form.active")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="active"
                      checked={values.active}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerBIConfigs.dialog.form.clientID")}
                      name="clientID"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerBIConfigs.dialog.form.clientSecret")}
                      name="clientSecret"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerBIConfigs.dialog.form.username")}
                      name="username"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Field
                      as={TextField}
                      type="password"
                      label={i18n.t("powerBIConfigs.dialog.form.password")}
                      name="password"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("powerBIConfigs.dialog.buttons.close")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {settingId
                    ? `${i18n.t("powerBIConfigs.dialog.buttons.edit")}`
                    : `${i18n.t("powerBIConfigs.dialog.buttons.add")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PowerBIConfigModal;
