import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Checkbox
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#fff"
  },

  tabmsg: {
    backgroundColor: theme.palette.webviewtab,
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  checkbox: {
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const powerReportSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  scale: Yup.number("Somente números"),
  settingId: Yup.number().required("Obrigatório")
});

const PowerReportModal = ({
  open,
  onClose,
  powerReportId,
  initialValues,
  onSave,
  resetPagination,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { companyId } = user;

  const initialState = {
    name: "",
    showFilters: false,
    navigationPage: false,
    language: "",
    localFormat: "",
    width: "",
    height: "",
    embedType: "",
    tenantID: "",
    reportID: "",
    reportSection: "",
    reportMode: "",
    scale: 0,
    orientation: "",
    settingId: "",
    groupID: "",
    active: false,
    companyId,
  };

  const [powerReport, setPowerReport] = useState(initialState);
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (initialValues) {
        setPowerReport((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      api.get(`/powerbi-config/`, { params: { companyId } })
        .then(({ data }) => {
          const fetchedSettings = data.records;
          const formattedSettings = fetchedSettings.map((tag) => ({
            id: tag.id,
            name: tag.name,
          }));
          setSettings(formattedSettings);
        })
        .catch((error) => {
          console.error("Error retrieving power report settings:", error);
        });

        
      if (!powerReportId) return;

      api.get(`/power-reports/${powerReportId}`).then(({ data }) => {
        setPowerReport(data);
      });
    }
  }, [powerReportId, open, initialValues, companyId]);

  const handleClose = () => {
    onClose();
    setPowerReport(initialState);
  };

  const handleSave = async (values) => {
    try {
      if (powerReportId) {
        await api.put(`/power-reports/${powerReportId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/power-reports", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("powerReports.toasts.success"));
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          { powerReportId
            ? `${i18n.t("powerReports.dialog.update")}`
            : `${i18n.t("powerReports.dialog.new")}`}
        </DialogTitle>
        <Formik
          initialValues={powerReport}
          enableReinitialize={true}
          validationSchema={powerReportSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSave(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Grid spacing={2} container>
                  <Grid xs={12} md={10} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.name")}
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid className={classes.checkbox} xs={1} md={1} item>
                    <InputLabel id="active">
                      {i18n.t("powerReports.dialog.form.active")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="active"
                      checked={values.active}
                      variant="outlined"
                      margin="dense"
                      className={classes.checkbox}
                    />
                  </Grid>
                  <Grid className={classes.checkbox} xs={6} md={3} item>
                    <InputLabel id="showFilters">
                      {i18n.t("powerReports.dialog.form.showFilters")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="showFilters"
                      checked={values.showFilters}
                      variant="outlined"
                      margin="dense"
                      className={classes.checkbox}
                    />
                  </Grid>
                  <Grid className={classes.checkbox} xs={6} md={3} item>
                    <InputLabel id="navigationPage">
                      {i18n.t("powerReports.dialog.form.navigationPage")}
                    </InputLabel>
                    <Field
                      as={Checkbox}
                      name="navigationPage"
                      checked={values.navigationPage}
                      variant="outlined"
                      margin="dense"
                      className={classes.checkbox}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.language")}
                      name="language"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.localFormat")}
                      name="localFormat"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.embedType")}
                      name="embedType"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.tenantID")}
                      name="tenantID"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.reportID")}
                      name="reportID"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.reportSection")}
                      name="reportSection"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.reportMode")}
                      name="reportMode"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.width")}
                      name="width"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.height")}
                      name="height"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.scale")}
                      name="scale"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.orientation")}
                      name="orientation"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Field
                      as={TextField}
                      label={i18n.t("powerReports.dialog.form.groupID")}
                      name="groupID"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="setting-selection-label">
                        {i18n.t("powerReports.dialog.form.setting")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("powerReports.dialog.form.setting")}
                        placeholder={i18n.t("powerReports.dialog.form.setting")}
                        labelId="setting-selection-label"
                        id="settingId"
                        name="settingId"
                        error={touched.settingId && Boolean(errors.settingId)}
                        helperText={touched.settingId && errors.settingId}
                      >
                        <MenuItem value="">Nenhuma</MenuItem>
                        {Array.isArray(settings) &&
                          settings.map((setting) => (
                            <MenuItem key={setting.id} value={setting.id}>
                              {setting.name}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("powerReports.dialog.buttons.close")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  { powerReportId
                    ? `${i18n.t("powerReports.dialog.buttons.edit")}`
                    : `${i18n.t("powerReports.dialog.buttons.add")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PowerReportModal;
