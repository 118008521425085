import React, { useState } from "react";
import { TextField, Button, Chip, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { i18n } from "../../translate/i18n";

function DaySelect({ daysList, setDaysList }) {
  const [day, setDay] = useState("");

  const handleAddDay = () => {
    if (day.trim() !== "") {
      const set = new Set(daysList);
      set.add(parseInt(day));
      setDaysList([...set]);
      setDay("");
    }
  };

  const handleDeleteDay = (index) => {
    const newDaysList = [...daysList];
    newDaysList.splice(index, 1);
    setDaysList(newDaysList);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3}>
        <TextField
          label={i18n.t("scheduleModal.form.days")}
          type="number"
          variant="outlined"
          margin="dense"
          value={day}
          inputProps={{ min: 1, max: 31 }}
          onChange={(e) => setDay(e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" color="primary" onClick={handleAddDay}>
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        {daysList.map((day, index) => (
          <Chip
            key={index}
            label={day}
            onDelete={() => handleDeleteDay(index)}
            style={{ marginRight: "8px", marginBottom: "8px" }}
          />
        ))}
      </Grid>
    </Grid>
  );
}

export default DaySelect;
